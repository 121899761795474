import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Config } from "src/app/config/config";
import { UntypedFormGroup } from "@angular/forms";
import { ApiResponse } from "src/app/typings/response";
import { saveAs } from "file-saver";

@Injectable({
  providedIn: "root",
})
export class InspectionService {
  public baseUrl: string;
  public baseUrlV2 = Config.api.getBaseUrlV2();

  constructor(private http: HttpClient) {
    this.baseUrl = Config.api.getBaseUrl();
  }

  raiseInspectionOrder(form: UntypedFormGroup): Promise<any> {
    return new Promise((resolve, reject) => {
      const payload = {
        contract: form.get("contract").value,
        date: form.get("inspectionDate").value,
        auditor: form.get("auditor").value,
        loggingRate: form.get("loggingRate").value,
        gliderCountRate: form.get("gliderCountRate").value,
        loadTestRate: form.get("loadTestRate").value,
        fixedFee: form.get("fixedFee").value,
        additionalLabourRate: form.get("additionalLabourRate").value,
        internalReference: form.get("internalReference").value,
        auditType: form.get("auditType").value,
        useBlueprint: form.get("useBlueprint").value,
        enforceAssetIDOnTracks: form.get("enforceAssetIDOnTracks").value,
      };

      this.http
        .post<ApiResponse>(this.baseUrlV2 + "/inspections/", payload)
        .subscribe(
          (res) => resolve(res.data),
          (err) => reject(err)
        );
    });
  }

  getInspection(id: string) {
    return new Promise((resolve, reject) => {
      this.http
        .get<ApiResponse>(this.baseUrlV2 + `/inspections/${id}`)
        .subscribe(
          (res) => resolve(res.data),
          (err) => reject(err)
        );
    });
  }

  downloadReport(url: string) {
    return new Promise((resolve, reject) => {
      this.http.get(url, { responseType: "blob" }).subscribe(
        (res) => resolve(res),
        (err) => reject(err)
      );
    });
  }

  markComplete(id: string) {
    return new Promise((resolve, reject) => {
      this.http
        .patch<ApiResponse>(this.baseUrlV2 + `/inspections/${id}`, {
          complete: true,
        })
        .subscribe(
          (res) => resolve(res.data),
          (err) => reject(err)
        );
    });
  }

  markPending(id: string) {
    return new Promise((resolve, reject) => {
      this.http
        .patch<ApiResponse>(this.baseUrlV2 + `/inspections/${id}`, {
          complete: false,
        })
        .subscribe(
          (res) => resolve(res.data),
          (err) => reject(err)
        );
    });
  }

  generateReport(id: string, includeInvoice: boolean) {
    return new Promise((resolve, reject) => {
      this.http
        .post<ApiResponse>(this.baseUrlV2 + `/reports/${id}/`, {
          invoice: includeInvoice,
        })
        .subscribe(
          (res) => resolve(res.data),
          (err) => reject(err)
        );
    });
  }

  generateMeasurementReport(
    id: string,
    contractName: string,
    customerName: string,
    internalReference: string
  ) {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.baseUrlV2 + `/reports/measurement-report/${id}`, {
          responseType: "blob",
        })
        .subscribe(
          (res) =>
            saveAs(
              res,
              `Measurement Report - ${contractName} ${internalReference} ${customerName} - ${new Date()}.xlsx`
            ),
          (err) => reject(err)
        );
    });
  }

  editInspection(id: string, changes: any) {
    return new Promise((resolve, reject) => {
      this.http
        .patch<ApiResponse>(this.baseUrlV2 + `/inspections/${id}/`, {
          ...changes,
        })
        .subscribe(
          (res) => resolve(res.data),
          (err) => reject(err)
        );
    });
  }

  sendReportToCustomer(
    inspectionId: string,
    reportId: string,
    primaryRecipients: string[],
    additionalRecipients: string[]
  ) {
    return new Promise((resolve, reject) => {
      this.http
        .post<ApiResponse>(
          this.baseUrlV2 +
            `/reports/${inspectionId}/${reportId}/sendToCustomer`,
          { primaryRecipients, additionalRecipients }
        )
        .subscribe(
          (res) => resolve(res.data),
          (err) => reject(err)
        );
    });
  }

  addInternalNote(inspectionId: string, note: string) {
    return new Promise((resolve, reject) => {
      this.http
        .post<ApiResponse>(
          this.baseUrlV2 + `/inspections/${inspectionId}/internal-notes`,
          { note }
        )
        .subscribe(
          (res) => resolve(res.data),
          (err) => reject(err)
        );
    });
  }

  addReportNote(inspectionId: string, note: string) {
    return new Promise((resolve, reject) => {
      this.http
        .post<ApiResponse>(
          this.baseUrlV2 + `/inspections/${inspectionId}/report-notes`,
          { note }
        )
        .subscribe(
          (res) => resolve(res.data),
          (err) => reject(err)
        );
    });
  }

  deleteInternalNote(inspectionId: string, note: string) {
    return new Promise((resolve, reject) => {
      this.http
        .patch<ApiResponse>(
          this.baseUrlV2 + `/inspections/${inspectionId}/internal-notes/`,
          { note }
        )
        .subscribe(
          (res) => resolve(res.data),
          (err) => reject(err)
        );
    });
  }
  deleteReportNote(inspectionId: string, note: string) {
    return new Promise((resolve, reject) => {
      this.http
        .patch<ApiResponse>(
          this.baseUrlV2 + `/inspections/${inspectionId}/report-notes`,
          { note }
        )
        .subscribe(
          (res) => resolve(res.data),
          (err) => reject(err)
        );
    });
  }

  getOpenRequests() {
    return new Promise((resolve, reject) => {
      this.http.get<ApiResponse>(this.baseUrlV2 + `/requests/`).subscribe(
        (res) => resolve(res.data),
        (err) => reject(err)
      );
    });
  }

  confirmInspectionRequest(requestId: string, formData: any) {
    return new Promise((resolve, reject) => {
      this.http
        .post<ApiResponse>(
          this.baseUrl + `/requests/request/${requestId}/confirm`,
          formData
        )
        .subscribe(
          (res) => resolve(res.data),
          (err) => reject(err)
        );
    });
  }

  convertToAdHoc(inspectionId: string) {
    return new Promise((resolve, reject) => {
      this.http
        .patch<ApiResponse>(
          this.baseUrlV2 + `/inspections/${inspectionId}/adhoc`,
          {}
        )
        .subscribe(
          (res) => resolve(res.data),
          (err) => reject(err)
        );
    });
  }

  convertToAudit(inspectionId: string) {
    return new Promise((resolve, reject) => {
      this.http
        .patch<ApiResponse>(
          this.baseUrlV2 + `/inspections/${inspectionId}/audit`,
          {}
        )
        .subscribe(
          (res) => resolve(res.data),
          (err) => reject(err)
        );
    });
  }

  deleteInspection(inspectionId: string) {
    return new Promise((resolve, reject) => {
      this.http
        .delete<ApiResponse>(this.baseUrlV2 + `/inspections/${inspectionId}`)
        .subscribe(
          (res) => resolve(res.data),
          (err) => reject(err)
        );
    });
  }

  generateBlueprint(inspectionId: string) {
    return new Promise((resolve, reject) => {
      this.http
        .post<ApiResponse>(
          this.baseUrlV2 + `/inspections/${inspectionId}/blueprint`,
          {}
        )
        .subscribe(
          (res) => resolve(res.data),
          (err) => reject(err)
        );
    });
  }

  openInspectionCount() {
    return new Promise((resolve, reject) => {
      this.http
        .get<ApiResponse>(this.baseUrlV2 + `/inspections/open/count`)
        .subscribe(
          (res) => resolve(res.data),
          (err) => reject(err)
        );
    });
  }

  changeInspectionType = (auditId: string, type: 'AUDIT' | 'PRODUCT_TESTING' | 'INSTALLATION') => {
    return new Promise((resolve, reject) => {
      this.http
        .patch<ApiResponse>(this.baseUrlV2 + `/inspections/${auditId}`, { auditType: type })
        .subscribe(
          (res) => resolve(res.data),
          (err) => reject(err)
        );
    });
  }

  completionRate() {
    return new Promise((resolve, reject) => {
      this.http
        .get<ApiResponse>(this.baseUrl + `/inspections/completionRate`)
        .subscribe(
          (res) => resolve(res.data),
          (err) => reject(err)
        );
    });
  }

  moveInspection(targetContract: string, auditId: string) {
    return new Promise((resolve, reject) => {
      this.http
        .post<ApiResponse>(
          this.baseUrlV2 + `/inspections/${auditId}/moveTo/${targetContract}`,
          {}
        )
        .subscribe(
          (res) => resolve(res.data),
          (err) => reject(err.data)
        );
    });
  }
}
