import { NotificationsService } from "./../../services/notifications/notifications.service";
import { MatSort } from "@angular/material/sort";
import { InspectionService } from "./../../services/inspection/inspection.service";
import { ContractsService } from "./../../services/contracts/contracts.service";
import { Contract } from "src/app/models/contract";
import { SafetyInspection } from "./../../models/safetyInspection";
import { IAudit } from "../../models/audit";
import { MatTableDataSource } from "@angular/material/table";
import { MatMenuTrigger } from "@angular/material/menu";
import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit, Inject, OnDestroy, EventEmitter, Output } from "@angular/core";
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormControl,
  FormControl,
} from "@angular/forms";
import { ContactsService } from "src/app/services/contacts/contacts.service";
import { ContactManageDialogComponent } from "../customer/customer.component";
import { menuAnimations } from "./dialogs/menu-animations";
import { Customer } from "src/app/models/customer.model";
import { CustomersService } from "src/app/services/customers/customers.service";
import { BehaviorSubject, Subject, Subscription, takeUntil } from "rxjs";
import { MatSelect } from "@angular/material/select";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";

import moment from "moment";
import { Contact } from "@app/models/contact.model";
import { Location } from "@angular/common";

@Component({
  selector: "app-contract",
  templateUrl: "./contract.component.html",
  styleUrls: ["./contract.component.scss"],
})
export class ContractComponent implements OnInit, OnDestroy {

  public displayedColumns: string[] = [
    "salesOrder",
    "reference",
    "auditor",
    "date",
    "abhoc",
    "createdAt",
    "actions"
  ];

  public auditTableDataSource = new MatTableDataSource([]);
  public contract: Contract;
  public contractId: string;

  public audits: BehaviorSubject<IAudit[]> = new BehaviorSubject([]);
  public subscriptions = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private contractsService: ContractsService,
    private dialog: MatDialog,
    private router: Router,
    private location: Location,
    private ns: NotificationsService
  ) { }

  ngOnInit() {
    this.getContract();
    this.audits.subscribe((audits) => {
      if (audits.length > 0) {
        this.auditTableDataSource.data = audits;
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  getContract() {
    this.route.params.subscribe((params) => {
      this.contractsService
        .getContract(params.id)
        .then((contract: Contract) => {
          this.contract = contract;
          this.contractId = params.id;

          // Assign all the audits to one property
          this.audits.next(contract.safetyInspections as any);
        })
        .catch((err) => {
          if (err.status == 404) {
            this.location.back();
          }
          console.log(err)
          this.ns.error(err.error.message || "An error occurred.", 3000)

        }

        );
    });
  }

  async openManageContractContact() {
    const dialogRef = this.dialog.open(ManageContractContactDialogComponent, {
      minWidth: "800px",
      data: {
        contract: this.contract,
      },
    });

    const d$ = dialogRef.afterClosed().subscribe((val) => {
      if (val) {
        this.getContract();
      }
    });

    this.subscriptions.add(d$);
  }

  async openManageEditDialog() {
    const dialogRef = this.dialog.open(ManageEditContractDialogComponent, {
      width: "500px",
      data: {
        contractName: this.contract.name,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        try {
          const c$ = this.contractsService.updateContractName(
            this.contract._id,
            result.contractName
          );
          this.ns.success("Successfully updated contract!", 3000);

          // Set the data
          this.contract.name = result.contractName;

        } catch (err) {
          this.ns.error("Unable to update contract.", 4000);
        }
      }
    });
  }

  async openRaiseInspectionDialog() {
    const dialog = this.dialog.open(NewInspectionDialogComponent, {
      width: "500px",
      minHeight: "500px",
      maxHeight: "700px",
      data: {
        contract: this.contractId,
        enforceAssetTracking: this.contract.customer.enforceAssetIDOnTracks,
      },
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.getContract();
      }
    });
  }

  trimLongReference(ref: string) {
    if (ref.length > 15) {
      return ref.substr(0, 15) + "...";
    } else {
      return ref;
    }
  }

  async openMoveAuditDialog(auditId: string) {
    const dialogRef = this.dialog.open(MoveInspectionDialogComponent, {
      width: "600px",
      data: {
        auditId,
        contract: this.contract,
      },
    });

    const d$ = dialogRef.afterClosed().subscribe((val) => {
      if (val) {
        this.getContract();
      }
    });

    this.subscriptions.add(d$);
  }

  back() {
    this.router.navigate([`/customers/customer/${this.contract.customer._id}`]);
  }

  viewInspection(id: string) {
    this.router.navigate([`/inspections/${id}`]);
  }

  convertToDaysAgo(date: any) {
    if (!date) {
      return "Never";
    }
    return moment().to(moment(date));
  }

  daysToExpiry(date: any) {
    return moment().to(moment(date).add(1, "year"));
  }
}

@Component({
  templateUrl: "./dialogs/edit-contract-dialog.html",
})
export class ManageEditContractDialogComponent {
  public editContractForm = this.fb.group({
    contractName: [this.data.contractName, Validators.required]
  })

  public subscriptions = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<ManageEditContractDialogComponent>,
    private ns: NotificationsService,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  submit(): void {
    if (this.editContractForm.valid) {
      this.dialogRef.close(this.editContractForm.value);
    }
    else {
      this.ns.error("Contract's name cannot be empty.", 5000)
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

@Component({
  templateUrl: "./dialogs/manage-contract-contact-dialog.html",
  animations: menuAnimations,
})
export class ManageContractContactDialogComponent implements OnInit {
  newContactId = new UntypedFormControl("", Validators.required);
  loading = false;

  contacts: Contact[];
  err: any;

  subs = new Subscription();

  constructor(
    private ns: NotificationsService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private fb: UntypedFormBuilder,
    private contactService: ContactsService,
    public dialogRef: MatDialogRef<ManageContractContactDialogComponent>,
    private contractService: ContractsService
  ) { }

  ngOnInit() {
    this.contactService
      .getAllContactsForCustomer(this.data.contract.customer._id)
      .then((contacts: any[]) => {
        this.contacts = contacts;
      })
      .catch((err) => {
        this.err = err.error.display;
      });
  }

  // save() {
  //   if (this.newContactId.invalid) {
  //     return;
  //   }

  //   this.loading = true;

  //   this.contractService
  //     .update(this.data.contract._id, {
  //       primaryContact: this.newContactId.value,
  //     })
  //     .then(() => {
  //       this.loading = false;
  //       this.ns.success("Successfully Added Contact.", 4000);
  //       this.dialogRef.close(true);
  //     })
  //     .catch((err) => {
  //       this.loading = false;
  //       this.ns.error(err.error.display, 4000);
  //     });
  // }

  makePrimary(contactId: string) {
    console.log('id', contactId)
    try {
      console.log(this.newContactId.value, 'id')
    } catch (error) {
      console.log(error, 'id error')
    }
    this.contractService
      .update(this.data.contract._id, { primaryContact: contactId })
      .then(() => {
        this.ns.success("Successfully updated primary contact.", 4000);
        this.loading = false;
        this.dialogRef.close(true);
      })
      .catch((err) => {
        this.ns.error(err.error.display, 4000);
      });
  }

  removeContact(contactId: string) {
    this.contactService.removeContactFromCustomer(contactId, this.data.customer._id)
      .then((result) => {
        this.ns.success("Successfully removed contact.", 4000);
        this.contacts = this.contacts.filter(contact => contact._id !== contactId)
      })
      .catch((err) => {
        this.ns.error(err.error.display, 4000);
      });
  }

}

@Component({
  templateUrl: "dialogs/new-audit-dialog.html",
})
export class NewInspectionDialogComponent implements OnInit {
  public inspectionForm = this.fb.group({
    auditor: ["", Validators.required],
    loggingRate: ["", Validators.required],
    loadTestRate: ["", Validators.required],
    gliderCountRate: ["", Validators.required],
    fixedFee: ["", Validators.required],
    additionalLabourRate: ["", Validators.required],
    inspectionDate: [""],
    contract: [this.data.contract],
    internalReference: ["", Validators.required],
    auditType: ["", Validators.required],
    useBlueprint: [""],
    enforceAssetIDOnTracks: ["", Validators.required],
  });

  loading = false;

  constructor(
    public dialogRef: MatDialogRef<NewInspectionDialogComponent>,
    private fb: UntypedFormBuilder,
    private inspectionsService: InspectionService,
    private ns: NotificationsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    // Set the default value for asset collection
    // if the customer has asset tracking enabled
    // also disable.
    this.inspectionForm
      .get("enforceAssetIDOnTracks")
      .setValue(this.data.enforceAssetTracking);
    if (this.data.enforceAssetTracking) {
      this.inspectionForm.get("enforceAssetIDOnTracks").disable();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  submit(): void {
    if (this.inspectionForm.invalid) {
      this.ns.error('All forms have to be filled in.', 5000);
      this.logFormErrors(this.inspectionForm);
      return;
    }
    console.log(this.inspectionForm);
    this.loading = true;

    if (!this.inspectionForm.get("useBlueprint").value) {
      this.inspectionForm.get("useBlueprint").setValue(false);
    }

    this.inspectionsService
      .raiseInspectionOrder(this.inspectionForm)
      .then((result: SafetyInspection) => {
        this.loading = false;
        this.dialogRef.close(true);
        this.ns.success(
          `Raised inspection request ${result.reference}`,
          4000
        );
      })
      .catch((err) => {
        this.loading = false;
        this.ns.error(err.error.message.toString(), 4000);
      });
  }

  logFormErrors(formGroup: any) {
    Object.keys(formGroup.controls).forEach(key => {
      const controlErrors = formGroup.get(key).errors;
      if (controlErrors != null) {
        console.log('Key:', key, 'Errors:', controlErrors);
      }
    });
  }
}


@Component({
  templateUrl: "./dialogs/move-inspection-dialog.html",
  providers: [MatSelect],
})
export class MoveInspectionDialogComponent implements OnInit, OnDestroy {
  public loading = false;
  public err: any = null;

  public moveAuditForm = this.fb.group({
    moveLocation: [null, Validators.required],
    targetOrg: [null, Validators.required],
    targetContract: [null, Validators.required],
  })

  public moveLocation = this.moveAuditForm.get("moveLocation")
  public targetOrg = this.moveAuditForm.get("targetOrg")
  public targetContract = this.moveAuditForm.get("targetContract")

  public customerContracts: any[];
  public contracts: Contract[];
  public filteredContracts: Contract[];
  public customers: Customer[];
  public filteredCustomers: Customer[];

  public subs = new Subscription();

  customerFilterControl: FormControl = new FormControl();
  contractFilterControl: FormControl = new FormControl();

  protected _onDestroy = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: { auditId: string; contract: Contract },
    public dialogRef: MatDialogRef<MoveInspectionDialogComponent>,
    private fb: UntypedFormBuilder,
    private contractService: ContractsService,
    private customersService: CustomersService,
    private inspectionService: InspectionService,
    private ns: NotificationsService,
    private router: Router
  ) { }

  ngOnInit() {
  this.getCustomers();

    const loc$ = this.moveLocation.valueChanges.subscribe((val) => {
      if (val == 1) {
        this.getContractsForSelectedOrg(this.data.contract.customer._id);
      }
    });

    this.subs.add(loc$);

    const target$ = this.targetOrg.valueChanges.subscribe((val) => {
      this.getContractsForSelectedOrg(this.targetOrg.value);
    });

    this.subs.add(target$);

    this.customerFilterControl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCustomers();
      });
      this.contractFilterControl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterContracts();
      })
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this.subs.unsubscribe();
    this._onDestroy.complete();
  }

  getContractsForSelectedOrg(orgId: string) {
    this.contractService
      .getContractsForCustomer(orgId)
      .then((contracts: Contract[]) => {
        this.contracts = contracts;
        this.filteredContracts = contracts;
        console.log(contracts);
        console.log(this.filteredContracts);
      });
  }

  async getCustomers() {
    //note: here we are setting the limit to a high number
    //      this is because, when left undefined, the limit is defaulted to 15.
    const pagination = { page:1, skip:0, limit: 9999};
    const filter = {};
    const sort = {};
    this.customersService
      .getAllCustomers(pagination, filter, sort)
      .then((customers: any) => {
        console.log(customers);
        this.customers = customers.data;
        this.filteredCustomers = customers.data; //also populating the filtered table prior to use.
      })
      .catch((err) => { console.log(err) });
  }

  filterCustomers() {
    if (!this.customers) {
      return;
    }

    let search = this.customerFilterControl.value;
    if(!search) {
      this.filteredCustomers = this.customers.slice();
      return;
    }
    else {
      search = search.toLowerCase();
    }

    this.filteredCustomers =
      this.customers.filter(customer => customer.organisationName.toLowerCase().indexOf(search) > -1)
  }

  filterContracts() {
    if (!this.contracts) {
      return;
    }

    let search = this.contractFilterControl.value;
    if(!search) {
      this.filteredContracts = this.contracts.slice();
      return;
    }
    else {
      search = search.toLowerCase();
    }

    this.filteredContracts =
      this.contracts.filter(contract => contract.name.toLowerCase().indexOf(search) > -1)
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  moveAudit() {
    if (this.moveAuditForm.invalid) {
      this.ns.error('A contract has to be selected.', 5000)
      return;
    }
    this.loading = true;
    this.inspectionService
      .moveInspection(this.targetContract.value, this.data.auditId)
      .then(() => {
        this.loading = false;
        this.ns.success("Moved Audit!", 4000);
        this.router.navigate(["contract/" + this.targetContract.value]);
        this.dialogRef.close();
      })
      .catch(() => {
        this.loading = false;
        this.ns.error("Something went wrong.", 4000);
      });
  }
}
