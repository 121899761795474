import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { BehaviorSubject, Subscription } from "rxjs";
import { Customer } from "src/app/models/customer.model";
import { AuditManagerService } from "src/app/services/audit-manager/audit-manager.service";
import { CustomersService } from "src/app/services/customers/customers.service";
import { NotificationsService } from "src/app/services/notifications/notifications.service";

@Component({
  selector: "app-audit-spec-manager",
  templateUrl: "./audit-spec-manager.component.html",
  styleUrls: ["./audit-spec-manager.component.scss"],
})
export class AuditSpecManagerComponent implements OnInit, OnDestroy {
  // Audit specifications will be stored here.
  private auditSpecifications: any = new BehaviorSubject([]);

  private loadingData = {
    customer: {
      organisationName: "Loading...",
    },
  };
  public dataSource: MatTableDataSource<any> = new MatTableDataSource([
    this.loadingData,
  ]);

  private err: any;

  private subscriptions = new Subscription();

  public displayedColumns = ["customer", "variations", "active", "actions"];

  constructor(
    private auditService: AuditManagerService,
    private ns: NotificationsService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getAllAuditSpecifications();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Function for getting all the audit specs
   */
  getAllAuditSpecifications() {
    this.auditService
      .getAllAuditSpecifications()
      .then((data: any[]) => {
        this.auditSpecifications.next(data);
        this.dataSource.data = data;
        console.log(data);
      })
      .catch((err) => {
        this.err = err;
      });
  }

  // Activate or deactivate an audit spec
  toggleActive(id: string, isActive: boolean) {
    this.auditService
      .updateAuditSpecification(id, { active: !isActive })
      .then(() => {
        this.dataSource.data.find((auditSpec) => auditSpec._id === id).active =
          !isActive;
      })
      .catch((err) => {
        this.ns.error(err.message.toString(), 4000);
      });
  }

  // delete audit spec
  removeAuditSpec(id: string) {
    this.auditService
      .removeAuditSpecification(id)
      .then(() => {
        this.dataSource.data = this.dataSource.data.filter(
          (auditSpec) => auditSpec._id !== id
        );
      })
      .catch((err) => {
        this.ns.error(err.message.toString(), 4000);
      });
  }

  openCreateAuditSpecDialog() {
    const dialogRef = this.dialog.open(CreateAuditSpecDialogComponent, {});

    const d$ = dialogRef.afterClosed().subscribe((val) => {
      this.getAllAuditSpecifications();
    });

    this.subscriptions.add(d$);
  }
}

@Component({
  selector: "app-audit-spec-manager-dialog",
  templateUrl: "./dialogs/create-audit-spec-dialog.html",
})
export class CreateAuditSpecDialogComponent implements OnInit {
  // new audit spec form
  newAuditSpecForm = this.fb.group({
    customer: ["", Validators.required],
  });

  public customers: Customer[] = [];

  public loading = false;

  constructor(
    private dialogRef: MatDialogRef<CreateAuditSpecDialogComponent>,
    private fb: UntypedFormBuilder,
    private customerService: CustomersService,
    private auditService: AuditManagerService,
    private ns: NotificationsService
  ) { }

  async ngOnInit() {
    await this.getCustomers();
  }

  async getCustomers() {
    try {
      const pagination = {
        page: 1, limit: 9999, skip: 0,
      }
      const filter = {}
      const sort = {
        organisationName: 1
      }
      const customers: any = await this.customerService.getAllCustomers(pagination, filter, sort);
      this.customers = customers.data;
    } catch (err) {
      console.log(err);
    }
  }

  // Create new audit spec
  async submit() {
    if (this.newAuditSpecForm.invalid) {
      this.ns.error('A customer has to be selected.', 5000)
      return;
    }

    this.auditService
      .createAuditSpecification(this.newAuditSpecForm.get("customer").value)
      .then((res) => {
        this.dialogRef.close();
      })
      .catch((err) => {
        console.log(err);
        this.ns.error(err.error.message, 4000);
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
