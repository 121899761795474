import { NotificationsService } from "./../../services/notifications/notifications.service";
import { SessionService } from "./../../services/session/session.service";
import { ProductService } from "./../../services/product/product.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Product } from "./../../models/product.model";
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  Inject,
  HostListener,
} from "@angular/core";
import { Location } from "@angular/common";
import { Subscription, Subject, BehaviorSubject } from "rxjs";
import {
  FormBuilder,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import anime from "animejs";
import { MatSort } from "@angular/material/sort";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";

interface GliderCountProduct {
  hasRedGliders: boolean;
  maximumRedGlidersPerTrack: number;

  hasGreyGliders: boolean;
  maximumGreyGlidersPerTrack: number;

  hasBlueGliders: boolean;
  maximumBlueGlidersPerTrack: number;

  hasYellowGliders: boolean;
  maximumYellowGlidersPerTrack: number;
}

@Component({
  selector: "app-product",
  templateUrl: "./product.component.html",
  styleUrls: ["./product.component.scss"],
})
export class ProductComponent implements OnInit, OnDestroy {
  @ViewChild("fileInput") fileInput: ElementRef;

  public canEditSafety: boolean;

  public loading = false;
  public product: Product;
  public isCustom;
  public loadTestFormLoading = false;

  public imageUrl = new BehaviorSubject("");
  public imageLoading = false;

  public inspectionType: string;
  public ragRiskRating: string;

  public showFormSaveButton = false;

  public showInspectionFormSave = false;

  public image: File;

  // Keep Track of subscriptions to unsubscribe from.
  public subscriptions = new Subscription();

  constructor(
    private dialog: MatDialog,
    private location: Location,
    private route: ActivatedRoute,
    private productService: ProductService,
    private fb: UntypedFormBuilder,
    private router: Router,
    public sessionService: SessionService,
    public ns: NotificationsService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.getProduct();
    // this.loadTestParameterForm.disable();

    this.canEditSafety = true;
  }

  openFileInput() {
    this.fileInput.nativeElement.click();
  }

  handleFileInput(files: FileList) {
    this.imageLoading = true;
    this.image = files.item(0);
    this.productService
      .uploadImage(this.product._id, files.item(0))
      .then((result: Product) => {
        this.imageUrl.next(result.imageUrl);
        this.product.imageUrl = result.imageUrl;
        this.imageLoading = false;
      })
      .catch((err) => {
        this.imageLoading = false;
      });
  }

  getProduct() {
    this.loading = true;

    this.subscriptions.unsubscribe();

    const params$ = this.route.params.subscribe((params) => {
      this.productService
        .getProduct(params.id)
        .then((product: Product) => {
          this.product = product;
          this.isCustom = product.isCustom;
          console.log(this.product);

          this.loading = false;
          this.inspectionType = this.calculateInspectionType(product);
          this.ragRiskRating = product.ragRiskRating;
          this.imageUrl.next(this.product.imageUrl);
        })
        .catch((err) => {
          this.back();
        });
    });

    params$.unsubscribe();

    this.subscriptions.add(params$);
  }

  isProductNotConfigured(): boolean {
    return (
      this.product.hasOwnProperty("isConfigured") &&
      this.product.isConfigured === false
    );
  }

  openEditDetailsDialog() {
    const dialogRef = this.dialog.open(EditProductDetailsDialogComponent, {
      width: "500px",
      minHeight: "300px",
      maxHeight: "700px",
      data: {
        product: this.product,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getProduct();
    });
  }

  onToggleChange() {
    this.productService
      .editProduct(this.product._id, {
        isCustom: !this.isCustom,
      })
      .then((r) => {
        this.isCustom = !this.isCustom;
      })
      .catch((err) => {
        console.log(err.error.message);
      });
  }

  allowForcePass() {
    this.productService
      .editProduct(this.product._id, {
        isCustom: true,
      })
      .then((r) => {
        this.product.isCustom = true;
      })
      .catch((err) => {
        console.log(err.error.message);
      });
  }

  disallowForcePass() {
    this.productService
      .editProduct(this.product._id, {
        isCustom: false,
      })
      .then((r) => {
        this.product.isCustom = false;
      })
      .catch((err) => {
        console.log(err.error.message);
      });
  }

  openEditLoadTestDialog() {
    const dialogRef = this.dialog.open(EditLoadTestDetailsDialogComponent, {
      width: "500px",
      minHeight: "300px",
      maxHeight: "700px",
      data: {
        product: this.product,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getProduct();
    });
  }

  openEditGliderCountDialog() {
    const dialogRef = this.dialog.open(EditGliderCountDetailsDialogComponent, {
      width: "500px",
      minHeight: "300px",
      maxHeight: "700px",
      data: {
        product: this.product,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getProduct();
    });
  }

  openDeleteProductDialog() {
    const dialogRef = this.dialog.open(DeleteProductDialogComponent, {
      width: "500px",
      minHeight: "200px",
      maxHeight: "700px",
      data: {
        product: this.product,
      },
    });
  }

  calculateInspectionType(product: Product) {
    if (this.product.isLoadTest) {
      return "Load Test";
    } else if (this.product.isGliderCount) {
      return "Glider Count";
    } else {
      return "None / Unknown";
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  back() {
    this.location.back();
  }
}

@Component({
  templateUrl: "./dialogs/edit-productDetails.dialog.html",
})
export class EditProductDetailsDialogComponent implements OnInit {
  @ViewChild("noteTextarea") noteTextarea: ElementRef;
  @HostListener("document:keydown.enter", ["$event"])
  onKeydownHandler(event: KeyboardEvent) {
    if (document.activeElement !== this.noteTextarea.nativeElement) {
      event.preventDefault();
      this.submit();
    }
  }

  private product = this.data.product;

  public productForm = this.fb.group({
    product: [this.product.product, Validators.required],
    manufacturer: [this.product.manufacturer, Validators.required],
    description: [this.product.description],
  });

  constructor(
    private fb: UntypedFormBuilder,
    private productService: ProductService,
    private ns: NotificationsService,
    private dialogRef: MatDialogRef<EditProductDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { product: Product }
  ) {}
  ngOnInit(): void {}

  submit() {
    if (this.productForm.invalid) {
      this.ns.error("All forms have to be filled in.", 5000);
      return;
    }

    this.productService
      .editProduct(this.data.product._id, {
        product: this.productForm.get("product").value,
        description: this.productForm.get("description").value || "",
        manufacturer: this.productForm.get("manufacturer").value,
      })
      .then((r) => {
        this.ns.success("Successfully updated product.", 5000);
        this.dialogRef.close();
      })
      .catch((err) => {
        console.log(err.error.message);
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  templateUrl: "./dialogs/edit-productSafety-dialog.html",
})
export class EditLoadTestDetailsDialogComponent {
  private product = this.data.product;
  public loadTestForm = this.fb.group({
    weightSeparationVerticalMinimum: [
      this.product.weightSeparationVerticalMinimum,
      Validators.required,
    ],
    weightSeparationVerticalMaximum: [
      this.product.weightSeparationVerticalMaximum,
      Validators.required,
    ],
    weightSeparationAngleMinimum: [
      this.product.weightSeparationAngleMinimum,
      Validators.required,
    ],
    weightSeparationAngleMaximum: [
      this.product.weightSeparationAngleMaximum,
      Validators.required,
    ],
  });

  constructor(
    private dialogRef: MatDialogRef<EditLoadTestDetailsDialogComponent>,
    private productService: ProductService,
    private ns: NotificationsService,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) private data: { product: Product }
  ) {}

  submit() {
    if (this.loadTestForm.invalid) {
      this.ns.error("All forms have to be filled in.", 5000);
      return;
    }

    const data = {
      weightSeparationVerticalMinimum: this.loadTestForm.get(
        "weightSeparationVerticalMinimum"
      ).value,
      weightSeparationVerticalMaximum: this.loadTestForm.get(
        "weightSeparationVerticalMaximum"
      ).value,
      weightSeparationAngleMinimum: this.loadTestForm.get(
        "weightSeparationAngleMinimum"
      ).value,
      weightSeparationAngleMaximum: this.loadTestForm.get(
        "weightSeparationAngleMaximum"
      ).value,
    };

    this.productService
      .editProduct(this.product._id, data)
      .then((res) => {
        this.ns.success("Updated load test parameters.", 5000);
        this.dialogRef.close();
      })
      .catch((err) => {
        this.ns.error(err.error.display, 4000);
        console.log(err.error.message);
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  templateUrl: "./dialogs/edit-productSafety-gc-dialog.html",
})
export class EditGliderCountDetailsDialogComponent {
  private product = this.data.product;

  public loadTestForm = this.fb.group({
    hasRedGliders: [this.product.hasRedGliders],
    maximumRedGlidersPerTrack: [
      this.product.maximumRedGlidersPerTrack,
      this.product.hasRedGliders ? Validators.required : null,
    ],
    hasGreyGliders: [this.product.hasGreyGliders],
    maximumGreyGlidersPerTrack: [
      this.product.maximumGreyGlidersPerTrack,
      this.product.hasGreyGliders ? Validators.required : null,
    ],
    hasBlueGliders: [this.product.hasBlueGliders],
    maximumBlueGlidersPerTrack: [
      this.product.maximumBlueGlidersPerTrack,
      this.product.hasBlueGliders ? Validators.required : null,
    ],
    hasYellowGliders: [this.product.hasYellowGliders],
    maximumYellowGlidersPerTrack: [
      this.product.maximumYellowGlidersPerTrack,
      this.product.hasYellowGliders ? Validators.required : null,
    ],
    hasWhiteGliders: [this.product.hasWhiteGliders],
    maximumWhiteGlidersPerTrack: [
      this.product.maximumWhiteGlidersPerTrack,
      this.product.hasWhiteGliders ? Validators.required : null,
    ],
  });

  constructor(
    private dialogRef: MatDialogRef<EditGliderCountDetailsDialogComponent>,
    private productService: ProductService,
    private ns: NotificationsService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private data: { product: Product }
  ) {
    this.setConditionalValidation();
  }

  setConditionalValidation() {
    this.loadTestForm.get("hasRedGliders").valueChanges.subscribe((checked) => {
      const maxControl = this.loadTestForm.get("maximumRedGlidersPerTrack");
      if (checked) {
        maxControl.setValidators(Validators.required);
      } else {
        maxControl.clearValidators();
      }
      maxControl.updateValueAndValidity();
    });

    this.loadTestForm
      .get("hasGreyGliders")
      .valueChanges.subscribe((checked) => {
        const maxControl = this.loadTestForm.get("maximumGreyGlidersPerTrack");
        if (checked) {
          maxControl.setValidators(Validators.required);
        } else {
          maxControl.clearValidators();
        }
        maxControl.updateValueAndValidity();
      });

    this.loadTestForm
      .get("hasBlueGliders")
      .valueChanges.subscribe((checked) => {
        const maxControl = this.loadTestForm.get("maximumBlueGlidersPerTrack");
        if (checked) {
          maxControl.setValidators(Validators.required);
        } else {
          maxControl.clearValidators();
        }
        maxControl.updateValueAndValidity();
      });

    this.loadTestForm
      .get("hasYellowGliders")
      .valueChanges.subscribe((checked) => {
        const maxControl = this.loadTestForm.get(
          "maximumYellowGlidersPerTrack"
        );
        if (checked) {
          maxControl.setValidators(Validators.required);
        } else {
          maxControl.clearValidators();
        }
        maxControl.updateValueAndValidity();
      });

    this.loadTestForm
      .get("hasWhiteGliders")
      .valueChanges.subscribe((checked) => {
        const maxControl = this.loadTestForm.get("maximumWhiteGlidersPerTrack");
        if (checked) {
          maxControl.setValidators(Validators.required);
        } else {
          maxControl.clearValidators();
        }
        maxControl.updateValueAndValidity();
      });
  }

  submit() {
    if (this.loadTestForm.invalid) {
      this.ns.error("All forms have to be filled in.", 5000);
      return;
    }

    const data = this.loadTestForm.value;

    this.productService
      .editProduct(this.product._id, data)
      .then((res) => {
        this.ns.success("Updated load test parameters.", 5000);
        this.dialogRef.close();
      })
      .catch((err) => {
        this.ns.error(err.error.display, 4000);
        console.log(err.error.message);
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
@Component({
  templateUrl: "./dialogs/delete-product-dialog.html",
})
export class DeleteProductDialogComponent implements OnInit {
  loading = false;
  confirmation = new UntypedFormControl(false);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { product: Product },
    private productService: ProductService,
    private ns: NotificationsService,
    private router: Router,
    private dialogRef: MatDialogRef<DeleteProductDialogComponent>
  ) {}

  ngOnInit() {}

  deleteProduct() {
    if (!this.confirmation.value) {
      this.ns.error("Please click the box to confirm your action.", 5000);
      return;
    }
    this.loading = true;
    this.productService
      .deleteProduct(this.data.product._id)
      .then(() => {
        this.loading = false;
        this.ns.success(`Deleted Product ${this.data.product.product}`, 4000);
        this.dialogRef.close();
        this.router.navigate([`/products`]);
      })
      .catch((err) => {
        this.loading = false;
        this.ns.error(err.error.display, 2000);
      });
  }
}
