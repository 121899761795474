<div class="p-10 rounded-xl overflow-y-scroll" (keydown.enter)="submit()">
  <!-- Dialog Title -->
  <h5 class="text-lg font-semibold mb-4 text-gray-900">Add a new Product</h5>

  <!-- Dialog Content -->
  <div>

    <!-- Form -->
    <form [formGroup]="newProductForm" class="space-y-4">
      <div class="w-full mt-1">
        <!-- Product Name / Model -->
        <div class="mb-4">
          <label class="block text-sm font-medium leading-6 text-gray-900">Product Name / Model</label>
          <input type="text" id="product" formControlName="product" required placeholder="Product Name / Model"
            autofocus title="product"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cg-orange sm:text-sm sm:leading-6">
        </div>

        <!-- Manufacturer -->
        <div class="mb-4">
          <label class="block text-sm font-medium leading-6 text-gray-900">Manufacturer</label>
          <input type="text" id="manufacturer" formControlName="manufacturer" required title="Manufacturer"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cg-orange sm:text-sm sm:leading-6">
        </div>

        <!-- Safety Feature -->
        <div class="mb-4 w-full">
          <label for="safetyFeature" class="block text-sm font-medium leading-6 text-gray-900">Safety Feature</label>
          <select id="safetyFeature" formControlName="safetyFeature"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cg-orange sm:text-sm sm:leading-6"
            required>
            <option value="Collapsible">Collapsible</option>
            <option value="Glider Separation">Glider Separation</option>
            <option value="Other">None / Unknown</option>
          </select>
        </div>

        <!-- Allow Forced Overwrite -->
        <div class="mb-4 w-full">
          <label for="allowForcedOverwrite" class="block text">Allow Forced Overwrite?</label>
          <select id="allowForcedOverwrite" formControlName="allowForcedOverwrite"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cg-orange sm:text-sm sm:leading-6"
            required>
            <option value="true">Yes - product can be forced to pass safety criteria</option>
            <option value="false">No - product cannot be forced to pass safety criteria</option>
          </select>
        </div>


        <!-- RAG Risk Rating -->
        <!-- <div class="mb-4">
          <label class="block text-sm font-medium leading-6 text-gray-900">RAG Risk Rating</label>
          <input type="text" id="ragRiskRating" formControlName="ragRiskRating" required
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cg-orange sm:text-sm sm:leading-6">
        </div> -->

        <div class="mb-4 w-full">
          <label for="ragRiskRating" class="block text-sm font-medium leading-6 text-gray-900">RAG Risk Rating</label>
          <select id="ragRiskRating" formControlName="ragRiskRating"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cg-orange sm:text-sm sm:leading-6"
            required>
            <option value="RED">Red</option>
            <option value="AMBER">Amber</option>
            <option value="GREEN">Green</option>
          </select>
        </div>


      </div>
    </form>
  </div>

  <!-- Dialog Actions -->
  <div class="flex justify-end space-x-2 mt-4">
    <button type="button"
      class="border border-gray-300 text-gray-700 rounded-md px-4 py-2 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
      (click)="onNoClick()">Cancel</button>
    <button type="button"
      class="bg-blue-600 text-white rounded-md px-4 py-2 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      (click)="submit()">Create</button>
  </div>

</div>
