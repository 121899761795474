<div class="bg-white px-4 sm:flex-nowrap sm:px-6 lg:px-8">

  <!-- Breadcrumbs -->
  <nav class="flex" aria-label="Breadcrumb">
    <ol role="list" class="flex items-center space-x-4">
      <li>
        <div>
          <a routerLink="/" class="navIcon text-gray-400 hover:text-gray-500">
            <svg class="h-5 w-5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M9.293 2.293a1 1 0 011.414 0l7 7A1 1 0 0117 11h-1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-3a1 1 0 00-1-1H9a1 1 0 00-1 1v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-6H3a1 1 0 01-.707-1.707l7-7z"
                clip-rule="evenodd" />
            </svg>
            <span class="sr-only">Home</span>
          </a>
        </div>
      </li>
      <li>
        <div class="flex items-center">
          <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clip-rule="evenodd" />
          </svg>
          <a routerLink="/contacts"
            class="navIcon ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">Contacts</a>
        </div>
      </li>
      <li>
        <div class="flex items-center">
          <svg class="h-5 w-5 flex-shrink-0 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clip-rule="evenodd" />
          </svg>
          <a class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 navIcon-last"
            aria-current="page">{{contact.name_first}} {{contact.name_last}}</a>
        </div>
      </li>
    </ol>
  </nav>


  <div class="items-center gap-6 mt-8">
    <h1 class="font-semibold text-xl leading-7 text-gray-900">{{contact.name_first}} {{contact.name_last}}</h1>
  </div>

  <div class="grid grid-cols-12">

    <!-- Contact Details -->
    <div class="col-span-6 mt-6">
      <dl>
        <!-- Contact Details Title -->
        <div class="bg-white py-2 min-h-10 grid-cols-6">
          <dt class="text-base text-left font-semibold leading-7 text-gray-900">Contact Details</dt>
        </div>

        <!-- Email -->
        <div class="bg-white py-2 sm:grid sm:grid-cols-3 sm:gap-2 min-h-10">
          <dt class="text-sm font-medium leading-6 text-gray-800">Email</dt>
          <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            <p>{{contact.email}}</p>
          </dd>
        </div>

        <!-- Phone -->
        <div class="bg-white py-2 sm:grid sm:grid-cols-3 sm:gap-2">
          <dt class="text-sm font-medium leading-6 text-gray-800">Phone</dt>
          <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            <p>{{contact.phone}}</p>
          </dd>
        </div>

        <!-- Created At -->
        <div class="bg-white py-2 sm:grid sm:grid-cols-3 sm:gap-2">
          <dt class="text-sm font-medium leading-6 text-gray-800">Created At</dt>
          <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            <p *ngIf="contact.createdAt">{{contact.createdAt | date:"dd/MM/Y"}}</p>
            <p *ngIf="!contact.createdAt">-</p>
          </dd>
        </div>

        <!-- Has Portal Access -->
        <div class="bg-white py-2 sm:grid sm:grid-cols-3 sm:gap-2 min-h-10">
          <dt class="text-sm font-medium leading-6 text-gray-800">Has Portal Access</dt>
          <dd class="flex mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 items-center">
            <div *ngIf="contact.accountActive" class="flex items-center text-green-400 font-medium">
              <ng-icon *ngIf="contact.accountActive" class="mr-2" size="20" name="heroCheckCircle"></ng-icon>
              Portal Enabled
            </div>
            <div *ngIf="!contact.accountActive" class="flex items-center text-red-400 font-medium mr-5">
              <ng-icon *ngIf="!contact.accountActive" class="mr-2" name="heroXCircle" size="20"></ng-icon>
              No Portal Access
            </div>

            <div *ngIf="!contact.accountActive" class="flex items-center">
              <button (click)="inviteToCustomerUser()" type="button"
                class="ml-auto flex items-center gap-x-1 rounded-md bg-cg-primary px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                <ng-icon name='heroPlus' size='18'></ng-icon>
                <span>Invite to Portal</span>
              </button>
            </div>


          </dd>
        </div>

        <!-- Last Login -->
        <div *ngIf="contact.accountActive" class="bg-white py-2 sm:grid sm:grid-cols-3 sm:gap-2  min-h-10">
          <dt class="text-sm font-medium leading-6 text-gray-800 mr-2">Last Login</dt>
          <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            <p *ngIf="contact.lastLogin">{{contact.lastLogin | date:"dd/MM/Y"}}</p>
            <p *ngIf="!contact.lastLogin">Never</p>
          </dd>
        </div>

        <!-- Forgotten Password? -->
        <div *ngIf="contact.accountActive" class="bg-white py-2 sm:grid sm:grid-cols-3 sm:gap-2 min-h-10">
          <dt class="text-sm font-medium leading-6 text-gray-800 mr-2">Forgot Password?</dt>
          <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            <a href="https://portal.safer-track.com/forgot-password"
              target="_blank">https://portal.safer-track.com/forgot-password</a>
          </dd>
        </div>
      </dl>


    </div>

  </div>

  <div class="relative mt-8">
    <div class="absolute inset-0 flex items-center" aria-hidden="true">
      <div class="w-full border-t border-gray-300"></div>
    </div>
  </div>

  <!-- Table -->
  <div>
    <div class="flex items-center py-8 sm:flex-nowrap">
      <h3 class="text-lg font-semibold leading-7 text-gray-900 mr-6">Assigned Customers</h3>
      <!-- <button (click)="addOrganisation()" class="hover:bg-gray-200 flex items-center ">
        <ng-icon name='heroPlus' size='18' class='text-gray-600'></ng-icon>
      </button> -->

      <button (click)="addOrganisation()" type="button"
        class="ml-auto flex items-center gap-x-1 rounded-md bg-cg-primary px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
        <ng-icon name='heroPlus' size='18'></ng-icon>
        <span>Assign Customer</span>
      </button>
    </div>

    <table cdk-table [dataSource]="assignedCustomerDataSource" cdkSort
      class="min-w-full divide-y divide-gray-300 bg-white">

      <h5 class="text-base font-medium mb-4 text-gray-700" *ngIf="contact.customers.length == 0">This contact
        hasn't been assigned to any customers.</h5>

      <!-- Name Column -->
      <ng-container cdkColumnDef="organisationName">
        <th cdk-header-cell *cdkHeaderCellDef cdk-sort-header
          class="py-3.5 text-left text-sm font-semibold text-gray-900">Name</th>
        <td cdk-cell *cdkCellDef="let element"
          class="align-top py-3 text-sm font-medium text-gray-700 leading-normal border-b border-gray-200">
          <a routerLink="/customers/customer/{{element._id}}">
            {{ element.organisationName }}</a>
        </td>
      </ng-container>

      <!-- Is Primary Column -->
      <ng-container cdkColumnDef="isPrimary">
        <th cdk-header-cell *cdkHeaderCellDef cdk-sort-header
          class="py-3.5 px-3 text-center text-sm font-semibold text-gray-900">Primary</th>
        <td cdk-cell *cdkCellDef="let element"
          class="align-top py-3 px-3 text-sm font-medium text-gray-700 leading-normal flex justify-center items-center border-b border-gray-200">

          <div *ngIf="element.primaryContact == contact._id" class="flex items-center text-green-400 font-medium">
            <ng-icon *ngIf="element.primaryContact == contact._id" class="ml-3" size="20"
              name="heroCheckCircle"></ng-icon>
          </div>
          <div *ngIf="element.primaryContact != contact._id" class="flex items-center text-red-400 font-medium">
            <ng-icon *ngIf="element.primaryContact != contact._id" class="ml-3" name="heroXCircle" size="20"></ng-icon>
          </div>
        </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container cdkColumnDef="actions">
        <th cdk-header-cell *cdkHeaderCellDef cdk-sort-header
          class="py-3.5 text-left text-sm font-semibold text-gray-900"></th>
        <td cdk-cell *cdkCellDef="let element"
          class="align-top py-3.5 px-3 text-sm font-medium text-gray-700 leading-normal flex justify-center items-center border-b border-gray-200">

          <div *ngIf="element.primaryContact == contact._id" class="flex items-center">
            <ng-icon name="heroExclamationTriangle" size="16" class="text-orange-500"
              matTooltip="Contact is primary contact, assign another contact as primary from the customer page to remove this customer"></ng-icon>
          </div>

          <div *ngIf="element.primaryContact != contact._id" class="flex items-center">
            <ng-icon name="heroUserMinus" size="16" class="hover:bg-gray-200"
              (click)="removeOrganisation(element._id)"></ng-icon>
          </div>
        </td>
      </ng-container>

      <!-- Rows -->
      <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
      <tr cdk-row *cdkRowDef="let row; columns: displayedColumns;"
        [ngClass]="{ 'complete': row.complete, 'incomplete': !row.complete }"></tr>

    </table>

    <!-- <mat-paginator [length]="paginationLength" [pageSize]="pageSize">
    </mat-paginator> -->

  </div>
</div>