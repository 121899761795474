import { MatSort } from "@angular/material/sort";
import { Contact } from "./../../models/contact.model";
import { ContactsService } from "./../../services/contacts/contacts.service";
import { MatMenuTrigger } from "@angular/material/menu";
import { NotificationsService } from "./../../services/notifications/notifications.service";
import { ContractsService } from "./../../services/contracts/contracts.service";
import { MatTableDataSource } from "@angular/material/table";
import { Customer } from "./../../models/customer.model";
import { CustomersService } from "./../../services/customers/customers.service";
import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  OnDestroy,
  AfterViewInit,
  EventEmitter,
  Output,
  ElementRef,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Contract } from "src/app/models/contract";
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormControl,
} from "@angular/forms";
import { Subscription, Observable } from "rxjs";
import { MatCheckbox } from "@angular/material/checkbox";
import { menuAnimations } from "./dialogs/menu-animations";
import { ManageEditCustomerDialogComponent } from "./dialogs/edit-customer-dialog/edit-customer-dialog";
import { Location } from "@angular/common";

@Component({
  selector: "app-customer",
  templateUrl: "./customer.component.html",
  styleUrls: ["./customer.component.scss"],
})
export class CustomerComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("menuTrigger", { static: true }) menuTrigger: MatMenuTrigger;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("automaticReports") automaticReports: MatCheckbox;
  @ViewChild("annualContract") annualContract: MatCheckbox;
  @ViewChild("enforceAssetTracking") enforceAssetTracking: MatCheckbox;
  @ViewChild("addressMenuTrigger")
  addressMenu: MatMenuTrigger;
  @ViewChild("orgNameMenuTrigger", { static: false })
  orgNameTrigger: MatMenuTrigger;

  public err: string;
  public customer: Customer;

  public contracts: MatTableDataSource<Contract> = new MatTableDataSource();
  public displayedColumns = [
    "name",
    "status",
    "lastInspection",
    "contact",
    "createdAt",
    "actions",
  ];

  page = 1;
  pageSize = 10;

  private subs = new Subscription();
  public filterInput = new UntypedFormControl();
  public contactEmail = new UntypedFormControl();

  public searchFormControl = new UntypedFormControl("");

  constructor(
    private route: ActivatedRoute,
    private customerService: CustomersService,
    private router: Router,
    private location: Location,
    private dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private ns: NotificationsService,
    private contractService: ContractsService
  ) { }

  ngOnInit() {
    this.getCustomer();

    const filter$ = this.filterInput.valueChanges.subscribe((val) => {
      this.contracts.filter = val;
    });

    this.subs.add(filter$);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngAfterViewInit() {
    // Connect the sort to the table data.
    // this.sort.sort({ id: 'name', start: 'asc', disableClear: false})
    this.contracts.sort = this.sort;
  }

  openManageContactDialog() {
    const dialogRef = this.dialog.open(ContactManageDialogComponent, {
      minWidth: "800px",
      data: {
        customer: this.customer,
      },
    });

    const x$ = dialogRef.componentInstance.refresh.subscribe(() => {
      this.getCustomer();
    });

    this.subs.add(x$);

    const d$ = dialogRef.afterClosed().subscribe((state) => {
      if (state) {
        this.getCustomer();
      }
    });

    this.subs.add(d$);
  }

  openManageEditDialog(): void {
    const dialogRef = this.dialog.open(ManageEditCustomerDialogComponent, {
      width: "500px",
      data: {
        organisationName: this.customer.organisationName,
        address: this.customer.address,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          const c$ = this.customerService.editCustomer(
            this.customer._id,
            result
          );
          const a$ = this.customerService.updateAddress(
            this.customer._id,
            result
          );

          this.ns.success("Successfully updated customer!", 3000);

          const response = await Promise.all([c$, a$]);
          console.log(response)

          this.ns.success("Successfully updated customer!", 3000);

          // Set the data
          this.customer.organisationName = result.organisationName;

          this.customer.address.address_line_1 = result.address_line_1;
          this.customer.address.address_line_2 = result.address_line_2;
          this.customer.address.county = result.county;
          this.customer.address.postcode = result.postcode;
        } catch (err) {
          this.ns.error("Unable to update customer.", 4000);
        }
      }
    });
  }

  getCustomer() {
    this.err = null;
    const params$ = this.route.params.subscribe((params) => {
      this.customerService
        .getCustomer(params.id)
        .then((customer: Customer) => {
          if (customer === undefined) {
            this.location.back();

            setTimeout(() => {
              this.ns.error(`Customer with ID "${params.id}" not found`, 5000);
            }, 500);
          }
          this.customer = customer;
          this.contracts.data = customer.contracts;

        })
        .catch((err) => {
          if (err.status === 404) {
            this.err = "Something went wrong.";
          } else {
            this.err = err.error.display;
          }
        });
    });

    this.subs.add(params$);
  }

  absolute(value: number) {
    return Math.abs(value);
  }

  parseStatus(status: string): string {
    switch (status) {
      case "Valid":
        return status;
      case "Invalid":
        return "Expired";
      default:
        return status;
    }
  }

  viewContract(id: string) {
    this.router.navigate(["/contract/" + id]);
  }

  toggleAnnualContract = (val: any) => {
    this.customerService
      .editCustomer(this.customer._id, { annualContract: val.target.checked })
      .then((response) => {
        this.ns.success("Successfully updated customer!", 3000);
        this.customer.annualContract = val.target.checked;
      })
      .catch((err) => {
        this.ns.error("Unable to update customer.", 4000);
        this.annualContract.toggle();
        this.customer.annualContract = !val.target.checked;
      });
  };

  toggleAutomaticReports = (val: any) => {
    this.customerService
      .editCustomer(this.customer._id, {
        automaticReminders: val.target.checked,
      })
      .then((response) => {
        this.ns.success("Successfully updated customer!", 3000);
        this.customer.automaticReminders = val.target.checked;
      })
      .catch((err) => {
        this.ns.error("Unable to update customer.", 4000);
        this.automaticReports.toggle();
        this.customer.automaticReminders = !val.target.checked;
      });
  };

  toggleEnforceAssetTracking = (val: any) => {
    this.customerService
      .editCustomer(this.customer._id, {
        enforceAssetIDOnTracks: val.target.checked,
      })
      .then((response) => {
        this.ns.success("Successfully updated customer!", 3000);
        this.customer.enforceAssetIDOnTracks = val.target.checked;
      })
      .catch((err) => {
        this.ns.error("Unable to update customer.", 4000);
        this.enforceAssetTracking.toggle();
        this.customer.enforceAssetIDOnTracks = !val.target.checked;
      });
  };

  createContract() {
    const dialogRef = this.dialog.open(CreateContractDialogComponent, {
      width: "400px",
      data: {
        customerId: this.customer._id,
        contact: this.customer.primaryContact,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getCustomer();
    });
  }

  deleteContract(contract: Contract) {
    const dialogRef = this.dialog.open(ContractDeleteConfirmationComponent, {
      data: contract,
    });

    const d$ = dialogRef.afterClosed().subscribe((val) => {
      this.getCustomer();

    });

    this.subs.add(d$);
  }

  openMoveContractToNewCustomerDialog = (contractId: string) => {
    const dialogRef = this.dialog.open(MoveContractDialogComponent, {
      width: "500px",
      minHeight: "200px",
      maxHeight: "700px",
      data: contractId,
    });

    const d$ = dialogRef.afterClosed().subscribe((val) => {
      this.getCustomer();
    });

    this.subs.add(d$);
  };

  archiveContract = (contractId: string) => {
    this.contractService
      .archiveContract(contractId)
      .then((res) => {
        this.ns.success("Successfully archived contract.", 3000);
        this.customer.archivedContractsCount += 1;

        this.customer.archivedContracts.push(
          this.customer.contracts.find((c) => c._id === contractId)
        );

        this.contracts.data = this.contracts.data.filter(
          (c) => c._id !== contractId
        );
      })
      .catch((err) => {
        this.ns.error("Unable to archive contract.", 3000);
      });
  };

  unarchiveContract(contract: Contract) {
    this.contractService
      .unarchiveContract(contract._id)
      .then((contract) => {
        this.ns.success("Successfully restored contract.", 3000);
        this.getCustomer();
      })
      .catch((err) => {
        this.ns.error(err.error.display, 3000);
      });
  }
}

@Component({
  templateUrl: './dialogs/move-contract-dialog.html'
})
export class MoveContractDialogComponent implements OnInit {
  customers: Customer[];
  targetCustomer = new UntypedFormControl();

  constructor(
    private customerService: CustomersService,
    private dialogRef: MatDialogRef<MoveContractDialogComponent>,
    private contractsService: ContractsService,
    private ns: NotificationsService,
    @Inject(MAT_DIALOG_DATA) private contractId: string
  ) { }

  ngOnInit() {
    this.getAllCustomers();
  }

  getAllCustomers(page = 1, limit = 9999, skip = 0) {
    return new Promise(async (resolve, reject) => {
      const pagination = { page, limit, skip };
      const filter = {};
      const sort = {};
      try {
        
        while (true) {
          const customers: any = await this.customerService.getAllCustomers(pagination, filter, sort);
          console.log(customers, this.customers);

          if (this.customers == undefined) {
            this.customers = customers.data
          }
          else {

            this.customers = [...new Set([...this.customers, ...customers.data])];
          }

          console.log(this.customers);

          if (this.customers.length >= customers.total) {
            break;
          }

          pagination.page += 1; // Move to the next page
          pagination.skip += 20
        }
        
       
        resolve(true);
      } catch (err) {
        reject(err);
      }
    });
  }

  getCustomers() {
    const pagination = {page: 1, limit: 9999, skip: 0};
    const filter = {};
    const sort = {};
    this.customerService
      .getAllCustomers(pagination, filter, sort)
      .then((customers: any) => {
        this.customers = customers.data;
      })
      .catch((err) => {
        this.dialogRef.close();
      });
  }

  submit() {
    if (this.targetCustomer.invalid) {
      this.ns.error('A customer has to be selected', 5000);
      return;
    }
    this.contractsService
      .moveContractToNewCustomer(this.contractId, this.targetCustomer.value)
      .then((res: any) => {
        this.ns.success(res, 4000);
        this.dialogRef.close(true);
      })
      .catch(() => { });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

/**
 * New Contract Dialog...
 */

@Component({
  selector: "app-create-contract-dialog",
  templateUrl: "dialogs/create-contract-dialog.html",
})
export class CreateContractDialogComponent {
  public newContract = this.fb.group({
    contractName: ["", Validators.required],
    customerId: ["", Validators.required],

    contactType: ["0"],

    contact_name_first: [""],
    contact_name_last: [""],
    contact_email: [""],
    contact_phone: [""],

    contactId: [""],

    lastInspectionDate: [""],
  });

  loading = false;

  constructor(
    public dialogRef: MatDialogRef<CreateContractDialogComponent>,
    public fb: UntypedFormBuilder,
    private contractsService: ContractsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ns: NotificationsService
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  submit() {
    this.newContract.get("customerId").setValue(this.data.customerId);

    if (this.newContract.get("contactType").value === "0") {
      if (!this.data.contact) {
        this.ns.error(
          "This contact is using a legacy contact. Please upgrade before creating a new contract for this customer.",
          3000
        );
        this.dialogRef.close(false);
        return;
      }
      this.newContract.get("contactId").setValue(this.data.contact._id);
    }

    if (this.newContract.invalid) {
      this.ns.error('All forms have to be filled in.', 5000);
      return;
    }

    this.loading = true;

    this.contractsService
      .createContract(this.newContract)
      .then((result) => {
        this.dialogRef.close(true);
        this.loading = false;
        this.ns.success("Created Contract.", 3000);
      })
      .catch((err) => {
        this.loading = false;
        this.ns.error(err.error.message, 4000);
      });
  }
}

@Component({
  templateUrl: './dialogs/delete-contract-dialog.html'
})
export class ContractDeleteConfirmationComponent implements OnInit {
  loading = false;

  confirmation = new UntypedFormControl(false);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Contract,
    public dialogRef: MatDialogRef<ContractDeleteConfirmationComponent>,
    private contractsService: ContractsService,
    private ns: NotificationsService
  ) { }

  ngOnInit() {
  }

  delete() {
    if (!this.confirmation.value) {
      this.ns.error('Please click the box to confirm your action.', 5000);
      return;
    }

    this.loading = true;
    this.contractsService
      .deleteContract(this.data._id)
      .then(() => {
        this.loading = false;
        this.ns.success(`Successfully deleted ${this.data.name}`, 4000);
        this.dialogRef.close(true);
      })
      .catch((err) => {
        this.loading = false;
        this.ns.error(err.error.display, 4000);
      });
  }
}

/**
 * ContactManageDialogComponent
 */
@Component({
  templateUrl: "./dialogs/manage-contacts-dialog.html",
  // styleUrls: ["./dialogs/manage-contacts-dialog.scss"],
  animations: menuAnimations,
})
export class ContactManageDialogComponent implements OnInit, OnDestroy {
  @Output() refresh = new EventEmitter();
  @ViewChild("menuButton") menuButton: ElementRef;

  // newContact = this.fb.group({
  //   first_name: ["", Validators.required],
  //   last_name: ["", Validators.required],
  //   email: ["", Validators.required],
  //   phone: ["", Validators.required],
  // });

  loading = false;

  contacts: Contact[];
  err: any;

  subs = new Subscription();

  constructor(
    private ns: NotificationsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private contactService: ContactsService,
    public dialogRef: MatDialogRef<ContactManageDialogComponent>,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    console.log('GET CONTACTS')
    this.getContacts();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  getContacts() {
    this.contactService
      .getAllContactsForCustomer(this.data.customer._id)
      .then((contacts: Contact[]) => {
        this.contacts = contacts;
      })
      .catch((err) => {
        this.err = err.error.display;
      });
  }

  makePrimary(contactId: string) {
    this.contactService
      .makeContactPrimary(this.data.customer._id, contactId)
      .then((result) => {
        this.ns.success("Successfully updated primary contact.", 4000);
        this.refresh.emit();
      })
      .catch((err) => {
        this.ns.error(err.error.display, 4000);
      });
  }

  removeContact(contactId: string) {
    this.contactService
      .removeContactFromCustomer(contactId, this.data.customer._id)
      .then((result) => {
        this.ns.success("Successfully removed contact.", 4000);
        this.getContacts();
      })
      .catch((err) => {
        this.ns.error(err.error.display, 4000);
      });
  }
}
