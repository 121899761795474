<!-- Search Form -->
<form class="relative flex flex-1 w-full" action="#" method="GET">
  <label for="search-field" class="sr-only">Search</label>
  <!-- <svg class="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400" viewBox="0 0 20 20"
    fill="currentColor" aria-hidden="true">
    <path fill-rule="evenodd"
      d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
      clip-rule="evenodd" />
  </svg> -->
  <ng-icon name="heroMagnifyingGlass" class="text-gray-500" size="14"></ng-icon>
  <input id="search-field" [formControl]="searchField" autocomplete="off"
    class="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
    placeholder="Search..." type="search" name="search" (input)="onSearchInput()" (focus)="onFocusInput()">
</form>

<!-- Command Palette -->
<div *ngIf="isOpen" #commandPalette
  class="absolute mt-2 w-full max-w-2xl rounded-2xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition-all z-10">
  <div class="relative bg-white rounded-xl">
    <!-- Search Results -->
    <div class="h-screen overflow-y-scroll divide-y divide-gray-100">
      <!-- Customers Section -->
      <div *ngIf="searchResults.customers.length > 0" class="p-2">
        <h2 class="mb-2 px-3 text-xs font-semibold text-gray-500">Customers</h2>
        <ul class="text-sm text-gray-700">
          <li *ngFor="let customer of searchResults.customers"
            class="group flex cursor-pointer select-none items-center rounded-md px-3 py-2 hover:bg-sky-950 hover:text-white"
            (click)="goToCustomer(customer._id)" tabindex="-1">
            <span class="ml-3 flex-auto truncate">{{ customer.organisationName }}</span>
          </li>
        </ul>
      </div>

      <!-- Contracts Section -->
      <div *ngIf="searchResults.contracts.length > 0" class="p-2">
        <h2 class="mb-2 px-3 text-xs font-semibold text-gray-500">Contracts</h2>
        <ul class="text-sm text-gray-700">
          <li *ngFor="let contract of searchResults.contracts"
            class="group flex cursor-pointer select-none items-center rounded-md px-3 py-2 hover:bg-sky-950 hover:text-white"
            (click)="goToContract(contract._id)" tabindex="-1">
            <span class="ml-3 flex-auto truncate">{{ contract.name }}</span>
          </li>
        </ul>
      </div>

      <!-- Inspections Section -->
      <div *ngIf="searchResults.inspections.length > 0" class="p-2">
        <h2 class="mb-2 px-3 text-xs font-semibold text-gray-500">Audits</h2>
        <ul class="text-sm text-gray-700">
          <li *ngFor="let inspection of searchResults.inspections"
            class="group flex cursor-pointer select-none items-center rounded-md px-3 py-2 hover:bg-sky-950 hover:text-white"
            (click)="goToInspection(inspection._id)" tabindex="-1">
            <span class="ml-3 flex-auto truncate">{{ inspection.internalReference }} - {{inspection.contract.customer.organisationName}} - {{inspection.contract.name}}</span>
          </li>
        </ul>
      </div>

      <!-- Empty State -->
      <div *ngIf="searchResults.customers.length === 0 && searchResults.contracts.length === 0 && searchResults.inspections.length === 0"
        class="p-4 text-sm text-gray-500">
        No results found.
      </div>
    </div>
  </div>
</div>