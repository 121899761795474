import { Router } from "@angular/router";
import { Product } from "./../../models/product.model";
import { ProductService } from "./../../services/product/product.service";
import { PageParams } from "src/app/typings/PageParams";
import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  AfterViewInit,
} from "@angular/core";
import { Subject, Subscription } from "rxjs";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { NewProduct } from "src/app/models/newProduct.model";
import { BaseTableComponent } from "../../directives/base-table.directive";
import { NotificationsService } from "../../services/notifications/notifications.service";

@Component({
  selector: "app-products",
  templateUrl: "./products.component.html",
  styleUrls: ["./products.component.scss"],
})
export class ProductsComponent
  extends BaseTableComponent<Product>
  implements OnInit, AfterViewInit, OnDestroy {
  public err: any = null;

  public params: PageParams = {
    title: "Products",
    description: "",
    docsLink: "",
    actions: [],
  };

  public displayedColumns: string[] = [
    "product",
    "manufacturer",
    "safetyFeature",
    "riskRating",
  ];

  public loading = false;
  public products: Product[];
  private subs = new Subscription();

  constructor(
    private productService: ProductService,
    private ns: NotificationsService,
    public dialog: MatDialog
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator.length = this.paginationLength;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.loadData();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  loadData() {
    this.loading = true;
    const params = this.getRequestParams();
    const filters = this.buildFilterQuery([
      "product",
      "manufacturer",
      "safetyFeature",
      "ragRiskRating",
    ]);

    this.productService
      .getAllProducts(params, filters, {})
      .then((products: any) => {
        console.log(products);
        this.products = products.data;
        this.paginationLength = products.total
        this.dataSource.data = products.data;
        this.loading = false;
      })
      .catch((err) => {
        this.err = err.error.message || err.error;
        this.loading = false;
      });
  }

  changeRagRisk(ragRiskRating: string, productId: string) {
    const product = this.products.find((p) => p._id === productId);
    if (product) {
      product.ragRiskRating = ragRiskRating;
      this.products = [...this.products];
      this.dataSource.data = [...this.products];

      // Make the API call to update the product's risk rating on the server
      this.productService
        .editProduct(productId, { ragRiskRating })
        .then(() => {
          this.ns.success("Product updated successfully", 4000);
        })
        .catch(() => {
          this.ns.error("Failed to update product", 4000);
        });
    } else {
      console.error(`Product with ID ${productId} not found.`);
    }
  }

  parseRiskRating(riskRating: string): string {
    switch (riskRating) {
      case "RED":
        return "Red";
      case "AMBER":
        return "Amber";
      case "GREEN":
        return "Green";
      case "FAIL":
        return "Fail";
      default:
        return "Not Set";
    }
  }

  async openCreateProduct() {
    const dialogRef = this.dialog.open(NewProductDialogComponent, {
      width: "500px",
      minHeight: "500px",
      maxHeight: "700px",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
      }
    });
  }
}

@Component({
  templateUrl: "./dialogs/newProductDialog.html",
  styleUrls: ["./products.component.scss"],
})
export class NewProductDialogComponent implements OnInit, OnDestroy {
  public loading = false;
  public err: any = null;

  public newProductForm = this.fb.group({
    product: ["", Validators.required],
    manufacturer: ["", Validators.required],
    safetyFeature: ["", Validators.required],
    allowForcedOverwrite: [false],
    isLoadTest: [false],
    isGliderCount: [false],
    isUnknown: [false],
    ragRiskRating: ["", Validators.required],
    isCustom: [false],
  });

  public subscriptions = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<NewProductDialogComponent>,
    private fb: UntypedFormBuilder,
    private router: Router,
    private productService: ProductService,
    private ns: NotificationsService,
  ) { }

  ngOnInit() {
    const safetyFeatures$ = this.newProductForm
      .get("safetyFeature")
      .valueChanges.subscribe((value) => {
        if (value === "Collapsible") {
          this.newProductForm.get("isLoadTest").setValue(true);
          this.newProductForm.get("isGliderCount").setValue(false);
          this.newProductForm.get("isUnknown").setValue(false);
        } else if (value === "Glider Separation") {
          this.newProductForm.get("isLoadTest").setValue(false);
          this.newProductForm.get("isGliderCount").setValue(true);
          this.newProductForm.get("isUnknown").setValue(false);
        } else {
          this.newProductForm.get("isLoadTest").setValue(false);
          this.newProductForm.get("isGliderCount").setValue(false);
          this.newProductForm.get("isUnknown").setValue(true);
        }
      });

    const isCustom$ = this.newProductForm.get("allowForcedOverwrite").valueChanges.subscribe((value) => {
      console.log(value);
      if (value) {
        this.newProductForm.get("isCustom").setValue(true);
      } else {
        this.newProductForm.get("isCustom").setValue(false);
      }
    });

    this.subscriptions.add(safetyFeatures$);
    this.subscriptions.add(isCustom$);

  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  submit() {
    if (this.newProductForm.invalid) {
      this.ns.error('All forms have to be filled in.', 5000);
      return;
    }

    const data: NewProduct = {
      product: this.newProductForm.get("product").value,
      manufacturer: this.newProductForm.get("manufacturer").value,
      safetyFeature: this.newProductForm.get("safetyFeature").value,
      isLoadTest: this.newProductForm.get("isLoadTest").value,
      isGliderCount: this.newProductForm.get("isGliderCount").value,
      isUnknown: this.newProductForm.get("isUnknown").value,
      ragRiskRating: this.newProductForm.get("ragRiskRating").value,
      isCustom: this.newProductForm.get("isCustom").value
    };

    this.loading = true;

    this.productService
      .createNewProduct(data)
      .then((product: Product) => {
        console.log(product);
        this.dialogRef.close();
        this.loading = false;
        this.router.navigate(["/products/product/" + product._id]);
      })
      .catch((err) => {
        this.loading = false;
        this.err = err.error.message || err.error;
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
